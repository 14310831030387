





































import {Component, Vue} from "vue-property-decorator";
import FormPanel from '@/resources/components/FormPanel.vue'
import Client from "@/app/Http/Client";

@Component({
    components: {
        FormPanel,
    },
})
export default class Create extends Vue {
    formData = {
        name: '',
        relation_id: this.$route.params.relationId,
        parent_id: this.$route.params.categoryId
    };

    goBack() {
        this.$router.go(-1)
    }

    submit() {
        if (this.formData.relation_id === 'overig') {
            this.formData.relation_id = ''
        }
        Client.post(`relations/${this.$route.params.relationId}/categories`, this.formData).then((response) => {
            this.$root.$emit('categoryAdded', (response.data as any).data)
            this.$router.go(-1)
        })
    }
}
