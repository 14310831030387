

















import BasePanel from "@/resources/components/BasePanel.vue";
import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {
        BasePanel,
    },
})
export default class FormPanel extends Vue {

    mounted() {
        this.open();
    }

    open() {
        (this.$refs.panel as BasePanel).open();
    }

    close() {
        (this.$refs.panel as BasePanel).close();
    }
}
