












import {Vue} from "vue-property-decorator";

export default class BasePanel extends Vue {
    opened: boolean = false;

    open() {
        this.opened = true;
        document.body.style.overflow = 'hidden';
    }

    close() {
        this.opened = false;
        document.body.style.overflow = '';
    }

    overlayClicked(e: { target: { classList: { contains: (arg0: string) => any; }; }; }) {
        if (e.target.classList.contains('overlay')) {
            this.$emit('overlay-clicked')
        }
    }
}
